<template>
  <div class="col-12 c">
    <div class="card">
      <div class="card-header">
        <h4 class="text-center">
          <i class="fa fa-file-o"></i>
          LOGS <strong class="text-danger">للمطورين</strong>
        </h4>
      </div>
      <div class="card-body">
        <div class="form-group">
          <label for="">اختر يوم معين</label>
          <input
            type="date"
            class="form-control"
            v-model="date"
            @change="get()"
          />
          <small id="helpId" class="form-text text-muted"
            >افتراضي يُعرض احدث 100 سجل. قم باختيار اليوم لعرض كل سجلاته</small
          >
        </div>
        <div class="col-12 table-responsive">
          <table class="table table-hover table-bordered custom-table">
            <thead>
              <th>date</th>
              <th>type</th>
              <th>details</th>
              <th>parent_id</th>
              <th>admin_id</th>
              <th>student_id</th>
              <th>bus_id</th>
              <th>data_id</th>
            </thead>
            <tbody>
              <tr v-for="log in logs" :key="log._id">
                <td>
                  {{ log.date }}
                </td>
                <td>
                  {{ log.type }}
                </td>
                <td>
                  <textarea
                    class="form-control"
                    v-model="log.details"
                    readonly
                  ></textarea>
                </td>
                <td>
                  {{ log.parent_id }}
                </td>
                <td>
                  {{ log.admin_id }}
                </td>
                <td>
                  {{ log.student_id }}
                </td>
                <td>
                  {{ log.bus_id }}
                </td>
                <td>
                  {{ log.data_id }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    var g = this;
    g.get();
  },
  data() {
    return {
      logs: [],
      user: JSON.parse(localStorage.getItem("user")),
      date: null,
    };
  },
  methods: {
    get() {
      var g = this;
      $.post(api + "/admin/logs", {
        jwt: g.user.jwt,
        date: g.date,
      })
        .then(function (r) {
          r = JSON.parse(r);
          g.logs = r;
        })
        .catch(function (r) {
          alert("حدث خطأ");
        });
    },
  },
};
</script>

<style>
</style>